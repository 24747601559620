<template>
  <div class="uk-container uk-margin-medium-top">
    <h2>My Billing History</h2>
    <div class="uk-card uk-card-default uk-card-body">
      <table class="uk-table tk-table-divider">
        <thead>
          <tr>
            <th>#</th>
            <th>Type</th>
            <th>Amount</th>
            <th>Payment Method</th>
            <th>Date</th>
            <th>Reference</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="transactions.length == 0">
            <td colspan="6">No transactions recorded.</td>
          </tr>
          <tr v-for="(transaction, i) in transactions" :key="`transction-${i}`">
            <td>{{ i+1 }}</td>
            <td>{{ transaction.type }}</td>
            <td>{{ transaction.currency }} {{ transaction.amount }}</td>
            <td>{{ transaction.method }}</td>
            <td>{{ transaction.date }}</td>
            <td>{{ transaction.reference }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      transactions: []
    }
  },

  methods: {
    fetch() {
      this.$axios.get('/api/v1/transactions').then(response => {
        this.transactions = response.data.transactions
      })
    }
  }
}
</script>